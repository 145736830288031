.warmup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 100vh;
  padding-top: 100px;

  .exercise-item {
    text-align: center;
    width: 1100px;

    .video {
      width: 100%;
    }
  }

  .timer-outside-container,
  .view-type-outside-container {
    width: calc((100vw - 1100px) / 2);
    display: flex;
    justify-content: center;
  }

  .timer-outside-container {
    position: relative;
    left: -20px;
    transform: scale(0.9);
  }

  .view-type-outside-container {
    position: relative;
    left: 20px;
  }

  &.type-switch {
    .timer-outside-container {
      top: -60px;
    }

    .view-type-outside-container {
      top: -48px;
    }
  }
}
