.timer-container {
  display: block;
  position: relative;
  width: 400px;
  transform: scale(2);
  margin-left: 220px;
  margin-right: -200px;

  .outer-circle {
    left: 0px;
    top: 2px;
    transform: scale(0.96);
  }

  svg circle {
    stroke-dasharray: 500px;
    stroke-dashoffset: 0px;
    stroke-linecap: round;
  }

  .innercircle-svg {
    transform: scale(1);
  }

  .timer-innercircle {
    background-color: #4fd950;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    position: relative;
    transform: scale(1.1);
  }

  .time {
    font-family: var(--font-family);
    font-size: 55px;
    color: #fff;
    position: absolute;
    left: 48px;
    top: 75px;
    text-align: center;
    width: 100px;
  }
}
