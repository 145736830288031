.circuit-list-container {
  text-align: center;

  table.circuit-list {
    border-collapse: collapse;
    width: 100%;

    th {
      border: 1px solid #ddd;
      padding: 5px 10px;
      cursor: pointer;

      .fas {
        margin-right: 10px;
        color: #888;
      }
    }

    td {
      border: 1px solid #ddd;
      padding: 5px 10px;

      cursor: pointer;

      a {
        color: #444;
        text-decoration: none;
      }
    }

    tr {
      &.circuit-item {
        &:hover {
          background-color: #f1f1f1;
        }

        .fa-times {
          color: #eb4d4b;
        }
        .fa-check {
          color: #6ab04c;
        }
      }
    }
  }

  .create-circuit-container {
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
    border: 1px solid #ddd;
    padding: 10px 15%;
    // border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    h3 {
      width: 200px;
      text-align: right;
    }

    button {
      // height: 30px;
      width: 200px;
    }

    input.circuit-name {
      width: 300px;
      // margin-top: 10px;
      // margin-bottom: 20px;
      border: 1px dashed #ccc;

      padding: 7px 10px;
      text-align: center;
      font-size: 1em;
      outline: 0;

      &:focus {
        border-color: #badc58;
        background-color: #fafafa;
      }
      // font-weight: bold;
    }
  }
}
