.view-type {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: scale(1.9);
  margin-top: 0px;
  // width: 300px;
  width: 580px;
  margin-left: -50px;

  img {
    width: 110px;
    height: 100px;
  }

  .number {
    font-size: 1.3em;
  }

  .separator {
    font-size: 1em;
    margin-left: 10px;
    margin-right: 5px;
    font-family: sans-serif;
    // text-transform: none;
    font-weight: bold;
  }

  .soft-hidden {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  .image-container {
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .heart-icon {
    margin-top: 20px;
    animation: scaleHeart 0.6s ease-in-out infinite alternate;
  }

  .rest-icon {
    margin-top: 15px;
    animation: scaleHeart 1.5s ease-in-out infinite alternate;
  }

  @keyframes scaleHeart {
    from {
      transform: scale(0.8);
    }

    to {
      transform: scale(1);
    }
  }

  .switch-icon {
    width: 100px;
    height: 90px;
    animation: spin 4s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(-360deg);
    }

    50%,
    100% {
      transform: rotate(0deg);
    }
  }

  .title {
    margin-top: -10px;
    margin-bottom: -10px;
    height: 50px;
    transform: scale(0.8);
  }

  .switch-title {
    width: 220px;
  }

  .rest-title {
    width: 130px;
  }

  .work-title {
    width: 180px;
  }

  .round-number {
    color: #fff;
    padding-top: 5px;
    text-transform: uppercase;
  }

  .round-number {
    font-family: var(--font-family);
    font-size: 30px;
    margin-top: 0px;
  }

  &.switch-class {
    margin-top: 40px;
  }
}
