@import "./Variables";

// uncomment for debugging
// * {
//   outline: solid 2px rgba(0, 0, 0, 0.2);
//   background-color: rgba(155, 50, 150, 0.05);
// }

html {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img,
video {
  max-width: 100%;
}

button {
  padding: 5px 10px;
  cursor: pointer;
  outline: 0;
}

.app {
  width: 100vw;
  overflow: hidden;
  background-color: transparent;
}
