.admin-container {
  display: flex;
  height: 100vh;
  background: #fff;

  .sidebar-outer-container {
    width: 90px;
  }
  .content-outer-container {
    height: 100%;
    overflow-y: auto;
    width: 100%;
    max-width: 1700px;
    margin: 0 auto;
    padding: 20px;
  }
}
