.progressbar-container {
  display: flex;
  align-self: flex-start;
  justify-content: center;
  width: 100%;

  .progressbar {
    border-radius: 5px;
    height: 15px;

    .dot {
      display: inline-block;
      width: 36px;
      height: 36px;
      background-color: #fff;
      border-radius: 50%;
      margin-left: -2px;
      z-index: 1;
    }

    .dot:first-child {
      margin-left: 0;
    }

    .full-dot {
      z-index: 1;

      position: relative;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        transform: translate(-50%, -50%) scale(0, 0);
        transform-origin: 50% 50%;
        animation-name: backgroundColor;
        animation-duration: 0.7s;
        animation-timing-function: cubic-bezier(0.6, 0.45, 0.51, 1.36);
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
      }
    }

    @keyframes backgroundColor {
      from {
        background-color: #fff;
        transform: translate(-50%, -50%) scale(0, 0);
      }
      to {
        background-color: #1ba0f6;
        transform: translate(-50%, -50%) scale(1.05, 1.05);
      }
    }

    .progress {
      background-color: white;
      margin-left: -2px;
    }

    .progress,
    .bar {
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 25px;
      width: 100px;
      height: 15px;
    }
  }
}
