.modal-container {
  background-color: rgba(0, 0, 0, 0.85);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0;

  animation-name: modal-bg-fade-in;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;

  @keyframes modal-bg-fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .modal {
    background-color: rgba(255, 255, 255, 1);
    max-width: 90vw;
    max-height: 90vh;
    position: fixed;
    transform: translate(-50%, -50%) scale(0.5);
    top: 50%;
    left: 50%;
    padding: 30px;
    z-index: 1;
    overflow: auto;
    min-height: 150px;

    opacity: 0;
    animation-name: modal-in;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-delay: 400ms;

    .button-container {
      text-align: center;
      margin-top: 30px;

      button {
        margin-right: 20px;
      }
    }
  }
}

@keyframes modal-in {
  from {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.5);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
