@import "../../Variables";

$exercise-card-height: 260px;

.station-container {
  display: flex;

  .station-details-inner-container {
    position: sticky;
    top: 85px;
  }

  .station-name {
    margin-top: 30px;
  }

  .exercise-picker-title {
    text-align: center;
    // margin-bottom: 20px;
    margin-top: -10px;
    margin-bottom: -20px;
  }

  .station-details {
    width: 300px;

    .checks-container {
      margin-top: 20px;

      .group {
        margin-top: 10px;
        display: flex;
        align-items: center;

        label {
          width: 140px;
          font-weight: bold;
        }

        input {
          border: 1px dashed #ccc;
          font-style: italic;
          padding: 5px 10px;
          text-align: center;
          width: 70px;
          font-size: 1em;
          font-weight: bold;
          background-color: transparent;
        }
      }

      &.static {
        .group {
          input {
            pointer-events: none;
            border: 0;
            font-style: normal;
          }
        }
      }
    }
  }

  .exercises-container {
    width: calc(100% - 300px);
  }

  .exercise-outer-container {
    position: relative;
    cursor: pointer;
    background-color: transparent;
    transition: height 400ms ease-in-out, opacity 50ms;
    transform: scaleY(1);
    height: $exercise-card-height;
    margin-bottom: 10px;

    &.dragging {
      opacity: 0;
    }

    &.shrunk {
      height: 0;
    }

    &:hover {
      background-color: #f8f8f8;
    }

    button.remove-exercise {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 1.4em;
      z-index: 1;
    }
  }

  .item-placeholder {
    height: 0;
    width: 100%;
    // background-color: red;
    animation-name: grow;
    animation-iteration-count: 1;
    animation-duration: 400ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;

    @keyframes grow {
      from {
        height: 0;
      }
      to {
        height: $exercise-card-height;
      }
    }
  }

  .drop-area {
    width: 100%;
    height: 220px;
    background-color: lightskyblue;
    position: relative;
    top: -15px;
    margin-top: -$exercise-card-height/2.4;
    margin-bottom: -$exercise-card-height/2.4;
    opacity: 0;
    transition: all 400ms;
    visibility: hidden;

    &.expanded {
      height: $exercise-card-height;
      margin-top: 0;
      margin-bottom: -$exercise-card-height;
    }

    &.visible {
      // opacity: 0.2;
      visibility: visible;
      // opacity: 0.6;
      // height: 50px;
    }
  }

  .new-exercise-container {
    display: flex;
    flex-direction: column;
    height: 200px;
    width: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 30px;
    margin-bottom: 30px;
    border: 1px solid #badc58;
    border-radius: 10px;

    button {
      background-color: transparent;
      border: 3px solid #badc58;
      color: #badc58;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      text-align: center;
      font-size: 2em;
      padding: 0;
      padding-bottom: 5px;
      outline: 0;
    }

    label {
      display: block;
      margin-top: 20px;
      color: darken(#badc58, 40);
    }

    &:hover {
      background-color: #fafafa;
    }
  }
}
