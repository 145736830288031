@import "../../Variables";

.exercise-list-preloader {
  margin-top: 40px;
  width: 1100px;
  height: 80vh;
  text-align: center;
}

.exercise-list-container {
  table {
    border-collapse: collapse;
    width: 100%;

    .no-filters-message {
      text-align: center;
      padding: 30px;
      opacity: 0.7;
    }

    th {
      border: 1px solid #ddd;
      padding: 5px 10px;
    }

    td {
      border: 1px solid #ddd;
      padding: 2px;

      &.exercise-name,
      &.exercise-date {
        padding-left: 10px;
      }
    }

    tr {
      &:hover {
        background-color: #f1f1f1;
      }
      &.exercise-item {
        cursor: pointer;
      }
    }
  }

  .video-thumbnail {
    max-height: 100px;
  }

  .preview-container {
    position: fixed;
    border: 2px solid #ccc;
    pointer-events: none;
    width: 250px;
    background-color: white;
    padding: 20px;
    text-align: center;
    // border-radius: 4px;

    p {
      margin-top: 10px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }

    animation-name: slide-up;
    animation-duration: 400ms;
    transform: translateY(10px);
    opacity: 0;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;

    @keyframes slide-up {
      from {
        transform: translateY(10px);
        opacity: 0;
      }
      to {
        transform: translateY(0px);
        opacity: 1;
      }
    }
  }

  .row {
    display: flex;
    justify-content: space-around;

    .exercise-list {
      width: 800px;
    }
    .exercise-filters {
      width: 100%;
    }
  }

  .exercise-list {
    margin-top: 67px;
    height: calc(100vh - 250px);
    min-height: 490px;
    overflow-y: auto;
  }

  .search-bar {
    width: 100%;
    position: relative;
    margin-bottom: 10px;

    .fa-search {
      position: absolute;
      left: 10px;
      color: #aaa;
      transform: translateY(-50%);
      top: 50%;
      color: $color2;
    }

    input {
      padding: 10px;
      width: 100%;
      padding-left: 35px;
      border: 2px solid $color2;
      outline: 0;

      &::placeholder {
        font-size: 13px;
        opacity: 0.7;
      }
    }
  }

  .no-data {
    margin-top: 90px;
    text-align: center;
  }
}
