@import "../../Variables";

.exercise-editor {
  .save-delete-buttons {
    display: flex;
    justify-content: space-between;

    .delete {
      background-color: #eb4d4b;
      border-color: #eb4d4b;
    }
  }

  .delete-modal {
    text-align: center;

    .button-container {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;

      .delete {
        background-color: #eb4d4b;
        border-color: #eb4d4b;
      }

      &.single {
        justify-content: center;
      }
    }

    .use-message {
      color: $darkGray;
      margin-top: 20px;

      ul {
        margin: 0 auto;
        margin-top: 10px;
        list-style-type: none;
        a {
          color: $color1;
        }
      }
    }
  }

  .video-upload-container {
    margin-right: 20px;
    position: relative;
    // background-color: #f1f1f1;

    input[type="file"] {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;

      &:hover {
        opacity: 0.02;
        background-color: black;
      }
    }
  }

  .details-row {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    label {
      font-size: 1.2em;
      margin-right: 20px;
      font-weight: bold;
    }

    input {
      font-size: 1.2em;
      color: #444;
      border: 1px dashed #ddd;
      padding: 5px 10px;
      width: 100%;
    }
  }

  .video-row {
    display: flex;
    width: 100%;

    .video-element {
      max-height: 200px;
    }

    .column {
      max-height: 400px;
      width: 100%;
      margin-top: 20px;
      border: 1px dashed #ddd;
      padding-top: 10px;
      padding: 20px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      p {
        text-align: center;
        font-weight: bold;

        &.uploaded-video-path {
          font-size: 1.5em;
          color: darken(#badc58, 20);
        }
      }

      label {
        color: #aaa;
        margin-top: 20px;
        display: block;
      }
    }
  }
}
