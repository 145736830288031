@import "../../Variables.scss";

.sidebar-container {
  border-right: 2px solid $lightGray;
  height: 100vh;

  a {
    display: block;
    text-align: center;
    padding-top: 1em;
    padding-bottom: 1em;
    color: $black;
    text-decoration: none;
    cursor: pointer;
    display: block;
    margin: 0 auto;
    font-size: 0.9rem;
    margin-bottom: 10px;

    &:hover {
      background-color: $lightGray;
    }

    .fas {
      font-size: 1.5em;
      margin-bottom: 5px;
    }

    &.selected {
      background-color: $color1;
      color: white;
    }
  }
}
