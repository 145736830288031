.exercise-container {
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 35px 15px 0px 15px;
  position: relative;
  height: 100%;

  .order-index {
    position: absolute;
    top: 15px;
    left: 15px;
  }

  .exercise-name {
    text-decoration: underline;
    font-weight: bold;
    font-size: 20px;
  }

  .muscle-list {
    font-size: 14px;
  }

  .info-item {
    margin-bottom: 10px;
    padding-right: 30px;
  }

  .image-container {
    width: 270px;
    padding-top: 8px;
  }

  .info-container {
    pointer-events: none;
    width: calc(100% - 270px);
  }

  .row {
    display: flex;
    margin-top: 5px;
    justify-content: space-around;

    label {
      font-weight: bold;
      width: 140px;
      text-align: right;
      margin-right: 10px;
    }

    p {
      text-align: left;
      font-size: 1em;
    }
  }

  .main-content {
    margin-top: 10px;
  }

  img {
    max-height: 120px;
    pointer-events: none;
  }
}
