.calendar-page {
  & > .circuit-details-outer-container {
    border: 1px solid #ddd;
    margin-top: 20px;
    padding: 20px;
    background-color: #fcfcfc;
  }

  .calendar-and-time {
    display: flex;
    justify-content: space-around;

    .calendar-container {
      width: 100%;
    }

    .circuit-time-container {
      width: 300px;
      margin-left: 10px;
      padding: 20px;
      margin-top: 50px;
      border: 1px solid #ddd;

      h3 {
        text-align: center;
      }

      button {
        width: 100%;
      }

      .time {
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
        margin-bottom: 20px;

        .column {
          width: 90px;
          text-align: center;

          p {
            color: #888;
          }

          input {
            margin-top: 10px;
            width: 100%;
            padding: 5px 10px;
            text-align: center;
            font-size: 1.2em;
            font-weight: bold;
          }
        }
      }

      hr {
        margin: 20px 0;
        background-color: #ddd;
        height: 1px;

        border: 0;
      }
    }
  }
}
