@import "../../Variables";

.circuit-container {
  .save-delete-buttons {
    display: flex;
    justify-content: space-between;

    .delete {
      background-color: #eb4d4b;
      border-color: #eb4d4b;
    }
  }

  .delete-modal {
    text-align: center;

    .button-container {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;

      .delete {
        background-color: #eb4d4b;
        border-color: #eb4d4b;
      }
    }

    .use-message {
      color: $darkGray;
      margin-top: 20px;

      ul {
        width: 100px;
        margin: 0 auto;
        margin-top: 10px;
      }
    }
  }

  .times-outer-container,
  .checks-outer-container {
    display: flex;
    justify-content: space-around;
  }

  .times-container,
  .checks-container {
    margin-top: 20px;

    .group {
      margin-top: 10px;
      display: flex;
      align-items: center;

      label {
        width: 140px;
        font-weight: bold;
      }

      input {
        border: 1px dashed #ccc;
        font-style: italic;
        padding: 5px 10px;
        text-align: center;
        width: 70px;
        font-size: 1em;
        font-weight: bold;
        background-color: transparent;
      }
    }

    &.static {
      .group {
        input {
          pointer-events: none;
          border: 0;
          font-style: normal;
        }
      }
    }
  }

  .checks-container {
    margin-top: 40px;
    font-size: 0.9em;

    h3 {
      margin-bottom: 20px;

      .fas {
        vertical-align: middle;
        font-size: 1.3em;
        margin-left: 10px;
        &.fa-times-circle {
          color: #eb4d4b;
        }
        &.fa-check-circle {
          color: #6ab04c;
        }
      }
    }
    .group {
      label {
        width: 180px;
      }
    }
  }

  .details-row {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    label {
      font-size: 1.2em;
      margin-right: 20px;
      font-weight: bold;
    }

    input {
      font-size: 1.2em;
      color: #444;
      border: 1px dashed #ddd;
      padding: 5px 10px;
      width: 100%;
    }
  }

  button.edit {
    margin-bottom: 20px;
  }

  .stations-container {
    position: sticky;
    top: -20px;
    z-index: 1;
    background-color: $white;
    padding-top: 20px;
    margin-top: -20px;
  }

  .stations {
    display: flex;
    width: 100%;
    border: 1px solid #ddd;
    margin-bottom: 40px;
    position: relative;

    .station-tab {
      list-style-type: none;
      width: 100%;

      .fas {
        vertical-align: bottom;
        margin-right: 10px;
      }

      &:not(:last-child) {
        border-right: 1px solid #ddd;
      }
      color: darken(#badc58, 20);
      font-weight: bold;
      text-align: center;
      padding: 5px;
      cursor: pointer;
      position: relative;
      user-select: none;

      &.selected {
        background-color: #eee;
        font-weight: bold;
        pointer-events: none;

        &::before {
          content: "";
          z-index: 0;
          position: absolute;
          left: 50%;
          bottom: -11px;
          display: block;
          border-left: 5px solid #eee;
          border-bottom: 5px solid #eee;
          background-color: #eee;
          width: 5px;
          height: 5px;
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }

      &.not-ready {
        color: darken(#eb4d4b, 0);
      }
    }
  }

  &.static-mode {
    input {
      border-color: transparent;
      background-color: transparent;
      pointer-events: none;
    }
  }
}
