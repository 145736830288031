.header-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  text-align: center;

  .time {
    font-weight: bold;
  }
}
