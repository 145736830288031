.nextworkout-container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 100vh;
  width: 100vw;

  .nextworkout {
    text-align: center;

    .title {
      margin-top: 0px;
      text-transform: uppercase;
      width: 70%;
    }
    p {
      font-size: 120px;
      font-family: var(--font-family);
      font-weight: bold;
      margin-top: 80px;
      text-align: center;
      letter-spacing: 5px;
    }
  }
}
