@import "../../Variables";

.item-list-container {
  .new-item-container {
    padding: 20px;
    border: 1px solid $lightGray;
    width: 500px;
    margin: 20px auto;

    .input-group {
      margin-bottom: 10px;
      margin-top: 10px;

      label {
        width: 150px;
        display: inline-block;
      }
    }
  }

  .edit-confirmation-modal {
    .title {
      text-align: center;
    }

    .use-message {
      margin-top: 10px;
      margin-bottom: 30px;

      ul {
        margin-top: 20px;
        color: $color1;
        text-align: center;
        list-style-type: none;
      }

      &.not-used {
        color: #aaa;
        text-align: center;
        margin-top: 30px;
      }
    }
  }

  .item-list {
    list-style-type: none;

    li {
      padding: 5px 10px;
      border: 1px solid $lightGray;
      margin-bottom: 10px;

      &:hover {
        background-color: $veryLightGray;
      }

      .name {
        display: inline-block;
      }

      .fas {
        cursor: pointer;

        &:hover {
          color: $color1;
        }
      }

      .fa-trash {
        margin-right: 30px;
        margin-left: 10px;
        float: right;
        position: relative;
        top: 2px;
      }
      .fa-edit,
      .fa-save {
        margin-right: 20px;
      }

      .fa-save {
        position: relative;
        top: 2px;
        color: $color2;
        &:hover {
          color: darken($color2, 20);
        }
      }

      input {
        width: calc(100% - 100px);
        padding: 3px;
        margin-top: -2px;
        margin-bottom: -2px;
        border: 1px solid $color2;
        color: darken($color2, 30);
        font-weight: bold;
      }
    }
  }
}
