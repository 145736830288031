@import "../../Variables.scss";

button {
  padding: 7px 12px;
  background-color: #f1f1f1;
  color: #666;
  text-align: center;
  cursor: pointer;
  font-size: 1em;
  border-radius: 5px;
  font-weight: bold;
  transition: all 300ms;
  transform-origin: 50% 50%;
  border: 0;

  .icon {
    margin-right: 10px;
    &.right {
      margin-right: 0px;
      margin-left: 10px;
    }
  }

  &:hover {
    background-color: #ddd;
    transform: scale(0.9);
  }

  &:active {
    background-color: #ddd;
    transform: scale(0.7);
  }

  &.primary {
    background-color: #4834d4;
    color: white;
  }

  &.close {
    background-color: transparent;

    .icon {
      margin-right: 0;
    }

    &:hover {
      color: #badc58;
    }
  }

  &.secondary {
    background-color: #badc58;
    color: white;
  }

  &.disabled {
    background-color: #ddd;
    pointer-events: none;
  }
}
