.group-exercise-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .center-row {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .exercise-item {
    width: 1100px;
    margin-left: 40px;
    margin-right: 60px;
    position: relative;
    left: 8px;

    .video {
      width: 100%;
    }
  }

  .timer-outside-container {
    width: calc((100vw - 1100px) / 2);

    .timer-container {
      transform: scale(2.3);
    }

    &.left {
      position: relative;
      left: 37px;
    }

    &.right {
      position: relative;
      left: -13px;
    }
  }
}
