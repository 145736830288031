.exercise-filters {
  // width: 550px;
  // display: flex;

  label {
    user-select: none;
    cursor: pointer;
  }

  input[type="checkbox"],
  input[type="radio"] {
    cursor: pointer;
  }

  input[type="radio"] + label {
    margin-left: 7px;
  }

  input[type="checkbox"] + label {
    margin-left: 5px;
  }

  h3 {
    margin-bottom: 15px;
    margin-top: 30px;
  }

  .column-container {
    display: flex;
    justify-content: space-around;
  }

  .row {
    display: flex;
    margin-bottom: 10px;
  }

  .column {
    width: 100%;
    text-align: center;
    // background-color: #fafafa;
    border: 1px solid #ddd;
    margin-right: 10px;
    padding: 10px;
    height: 240px;
    overflow-y: auto;

    .group-name {
      font-size: 1em;
      margin-bottom: 20px;
    }

    .input-group {
      margin-bottom: 5px;
    }
  }

  .type-container {
    width: 100%;

    .group-name {
      margin-top: 29px;
      font-size: 19px;
      font-weight: bold;
    }
  }

  .input-group {
    display: block;
    // width: 150px;
    text-align: left;
    cursor: pointer;
  }
}
