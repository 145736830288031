.station-playback-container {
  $margin: 20px;

  width: 100vw;
  height: 100vh;
  overflow: hidden;
  // padding-top: $margin * 3;
  // padding-bottom: $margin * 3;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .before-group-exercise-mark {
    position: fixed;
    top: 0;
    right: 0px;
    width: 30px;
    height: 30px;
    border-right: 5px solid rgba(255, 255, 255, 0.3);
    border-top: 5px solid rgba(255, 255, 255, 0.3);
    z-index: 10;
  }

  .timer-outside-container {
    // margin-left: 20px;
    // margin-right: 40px;
    position: relative;
    left: 15px;
  }

  .timer-outside-container,
  .view-type-outside-container {
    width: calc((100vw - 960px) / 2);
  }

  .top-row,
  .bottom-row {
    display: flex;
    align-items: center;
    // padding-top: $margin;
    // padding-bottom: $margin;
  }

  .top-row {
    justify-content: space-evenly;
    // margin-bottom: -30px;
  }

  .bottom-row {
    justify-content: center;
  }

  .video-container {
    position: relative;
    display: inline-block;
    width: 960px !important;
    height: 540px !important;
    flex-grow: 0;

    .letter {
      position: absolute;
      left: 15px;
      bottom: 20px;
      width: 75px;
    }

    .video {
      width: 100%;
    }
  }
}
