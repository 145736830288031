@import "../Variables";

@keyframes playbutton {
  0% {
    transform: translate(-50%, -50%) scale(0) rotate(180deg);
  }
  100% {
    transform: translate(-50%, -50%) scale(1) rotate(0);
  }
}

.playback-container {
  .using-server-time {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    font-size: 20px;
    color: red;
  }

  .error-message {
    text-align: center;
    margin-top: calc(50vh - 30px);
  }

  .hidden {
    display: none;
  }

  .hidden-view-type-images-container {
    display: none;
  }
}

.user-time-offset {
  position: fixed;
  top: 10vh;
  left: 50vw;
  font-size: 30px;
  transform: translate(-50%, -50%);
  text-align: center;
}

.time-offset-label {
  position: fixed;
  top: calc(10vh - 50px);
  left: 50vw;
  font-size: 30px;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.playback-message {
  position: fixed;
  top: 55vh;
  left: 50vw;
  font-size: 90px;
  padding: 40px 70px;
  transform: translate(-50%, -50%) !important;
  color: white;
  width: 90vw;
  text-align: center;

  animation-name: appear-text;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;

  margin-bottom: -20px;

  @keyframes appear-text {
    from {
      opacity: 0;
      top: 55vh;
    }
    to {
      opacity: 1;
      top: 50vh;
    }
  }
}

body {
  background-color: $playback-bg-color;
}
