@import "../../Variables.scss";

.calendar-container {
  width: 100%;
  max-width: 1000px;
  margin: 0;

  button {
    padding: 5px 10px;
  }

  .top-bar {
    display: flex;
    justify-content: space-around;
  }

  table.calendar {
    margin-top: 20px;
    border-collapse: collapse;
    width: 100%;

    th {
      border: 1px solid #eee;
      padding: 5px 10px;
    }

    td {
      text-align: center;
      border: 1px solid #eee;
      padding: 5px 5px;

      &.day {
        position: relative;

        &.real {
          cursor: pointer;

          &:hover {
            background-color: rgba(0, 0, 0, 0.05);
            color: $black;
          }

          &.today {
            background-color: $lightGray;
            color: $black;
            font-weight: bold;
          }

          &.selected {
            background-color: $black;
            color: $white;
          }
        }

        @keyframes appear {
          from {
            opacity: 0;
            bottom: -60px;
          }
          to {
            opacity: 1;
            bottom: -50px;
          }
        }

        .hover-info {
          background-color: $black;
          position: absolute;
          color: $white;
          border-radius: 5px;
          padding: 5px 10px;
          bottom: -50px;
          transform: translateX(-50%);
          left: 50%;
          width: 200px;
          z-index: 1;
          opacity: 0;

          animation-name: appear;
          animation-duration: 400ms;
          animation-fill-mode: forwards;
          animation-timing-function: ease-in-out;
        }

        .duplicated {
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          position: relative;
          top: 4px;
          margin-left: -18px;
          left: 22px;
          color: #f9ca24;
        }

        .circuit-name {
          width: 130px;
          color: $darkGray;
          font-size: 12px;
          text-align: center;
          margin: 0 auto;
        }

        .mark {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          margin: 0 auto;
          display: inline-block;

          &.empty {
            background-color: #eb4d4b;
          }
          &.full {
            background-color: #6ab04c;
          }
        }
      }
    }
  }
}
