.admin-playback {
  .date-container {
    margin-top: 20px;

    input {
      margin-left: 20px;
      font-size: 15px;
      text-align: center;
    }
  }

  .stations-container {
    margin-top: 20px;
    button {
      margin-right: 10px;
    }
  }

  hr {
    border: 1px solid #eee;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
