@import "../../Variables";

.not-interacted-with-container {
  .start-playback {
    position: fixed;
    top: 50vh;
    left: 50vw;
    border: 0;
    background-color: transparent !important;
    padding: 0;
    transform: translate(-50%, -50%) scale(0.2);
    font-size: 120px;
    border: 5px solid white !important;
    width: 300px;
    transition: all 300ms;
    border-radius: 50%;
    height: 300px;
    z-index: 2;
    animation: playbutton 1s 1;
    animation-fill-mode: forwards;

    i {
      margin-left: 30px;
    }

    &:hover {
      color: lighten($playback-bg-color, 45);
      border-color: lighten($playback-bg-color, 45) !important;
      border-width: 20px !important;
      transform: translate(-50%, -50%) scale(0.95) rotate(250deg);
    }
  }
}
