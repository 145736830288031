.circuit-picker-container {
  max-width: calc(90vw - 60px);
  width: 650px;
  text-align: center;

  .dropdown-container {
    margin-bottom: 20px;
    margin-top: 10px;
  }

  .time-container {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 300px;
    margin-left: auto;
    margin-right: auto;

    .column {
      width: 100px;
      text-align: center;

      p {
        color: #888;
      }

      input {
        margin-top: 10px;
        width: 100%;
        padding: 5px 10px;
        text-align: center;
        font-size: 1.2em;
        font-weight: bold;
      }
    }
  }

  button {
    width: 200px;
  }
}
